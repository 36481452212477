import {
	LOADING_UPDATE,
	TRACK_RECOMMENDATIONS_ERROR,
} from '../actions/types';

export default function(state={loading:false},action){
	switch(action.type){
		case LOADING_UPDATE: //Fue exitosa la Auth
			return {...state, loading: action.payload};
		case TRACK_RECOMMENDATIONS_ERROR:
			return {...state, recommendation_error: action.payload};
		default:
			return state;
	}
}