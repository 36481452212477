import {
	AUTH_USER,
	UNAUTH_USER,
} from '../actions/types';

export default function(state={},action){
	switch(action.type){
		case AUTH_USER: //Fue exitosa la Auth
			return {...state, ...action.payload};
		case UNAUTH_USER:
			return {};
		default:
			return state;
	}
}