import React from 'react';
import { connect } from "react-redux";
import { fetchRecommendations, playSong, setCurrent, clearCurrent } from '../../actions';
import _ from 'lodash';
import './playlists.scss';

import * as Vibrant from 'node-vibrant';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronCircleRight, faChevronCircleLeft, faPlay, faPlusCircle  } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
library.add( faChevronCircleRight, faChevronCircleLeft, faPlay, faPlusCircle )


class Card extends React.Component {

  constructor(props) {
    super(props);
    this.picture = React.createRef();
    this.state = { vibrant_color: undefined }
  }

  componentDidMount(){
   this.picture.current.crossOrigin = "anonymous";
   Vibrant.from( this.picture.current ).getPalette().then( (palette) => this.setState( {vibrant_color:palette.Vibrant} ) )   
  }

  render() {
   const { playlist, onImageClick, onPlayClick } = this.props;
   const { vibrant_color } = this.state;

   return <div className="playlist-card d-flex flex-column">
     <div className="image"> 
      <img onClick={ e=>onImageClick?onImageClick(e):null } ref={this.picture} src={playlist.picture.file} alt=""/>
     </div> 
     
     <div className="card-body position-relative">
       <div onClick={ e=>onPlayClick?onPlayClick(e):null } style={{backgroundColor:vibrant_color?`${vibrant_color.hex}`:''}} className={`${vibrant_color?'':'invisible'} main-button d-flex justify-content-center align-items-center`}>
        <FontAwesomeIcon className="play-button" icon={['fa', 'play']}/>
       </div>

       <div className="d-flex flex-column">
          <span className="title">{playlist.name}</span>
          <span className="subtitle">{playlist.created.substring(0,10)}</span>
       </div>
     </div>

   </div>
  }
}

class Uploads extends React.Component {
   constructor(props) {
      super(props)
      
      this.state = {}
   }

   componentDidMount() {
   		if( _.isEmpty( this.props.playlists ) ){
   			this.props.fetchRecommendations(1);
   		}
   }

   setCurrent( playlist ){
      this.props.setCurrent(playlist);
   }

   playSongs( { recommendations } ){
      const toPlay = recommendations.map( ({song}) => `spotify:track:${song.spotify_id}` )
      this.props.playSong( toPlay );
   }

   render() {
      const { current, clearCurrent, playlists } = this.props;
      
      if( _.isEmpty(playlists) ){
        return <div className="empty-playlists">
          <h1>Start uploading pictures to see your recommendations over here</h1>
        </div>
      }

      return <div className="uploads-container">
      	
      	<div className="menu text-center d-flex justify-content-start align-items-center">
      		<p className="tag noselect"> <span className="active"> Playlists </span> </p>
      		<p className="tag noselect"> <span className=""> Artists </span> </p>
      	</div>

        
        <div onClick={ e=>{ clearCurrent() } } className={`${current===undefined?'hidden':''} new-playlist-button d-flex justify-content-center align-items-center animated`}>
          <FontAwesomeIcon className="" icon={['fa', 'plus-circle']}/>
        </div>
        

         <div className="cards-row d-flex flex-row flex-nowrap">

            { _.map( playlists, (val,key) => { return <Card key={key} playlist={val} onImageClick={ (e) => {this.setCurrent(val)}} onPlayClick={ (e) => { this.playSongs(val) } } /> })}

         </div>

      </div>
   }
}

function mapStateToProps( {playlists} ) {
   return { playlists: _.omit(playlists,'current'), current: playlists.current }
}

export default connect( mapStateToProps, { fetchRecommendations, playSong, setCurrent, clearCurrent } )( Uploads )
