import React, { useState, useEffect } from 'react';
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import './header.scss';
import { signoutUser } from "./../../actions"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleDown, faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
library.add( faAngleDown, faBars, faTimes )

function isMobile(){ return window.innerWidth<992 };

function Header(props) {
  // State & Props
  const [ mobile, setMobile]         = useState( isMobile() );
  const [ activeMenu, setActiveMenu] = useState( false );
  const { pathname } = props.location;
  
  //FUNCTIONS 
  const resize = e=>{ setMobile( isMobile() );  }
  
  // EFFECTS
  useEffect(() => {
  	  window.addEventListener('resize', resize);
	  return () => { // Clean up
	    window.removeEventListener('resize', resize);
	};
  });

  return (

    <div className={`${activeMenu?"mobile-nav-active":""}`}>
    	<div id="header">	

		  <div className="container">
		    <div className="logo float-left">
		      <h1 className="text-light"> <Link to="/"><span>Soundpic</span></Link> </h1>
		    </div>
		    <nav className={mobile?"mobile-nav d-lg-none":"main-nav float-right d-none d-lg-block"}>
		      <ul>        
		        

		        
		        {/*<li className={pathname==='/classify' ?"active":""}> <Link to="/classify">Classify</Link> </li>*/}
		        <li className={pathname==='/'?"active":""}> <Link to="/">Upload</Link> </li>
		    	
		        
		        {mobile && <li><a onClick={e=>{e.preventDefault(); props.signoutUser()}} href="/logout">Logout</a></li>}
		        {!mobile && <li className="drop-down">
		        	<Link to="/"> {props.auth.user.name} <FontAwesomeIcon icon={['fa', 'angle-down']}/></Link>
		    		<ul>
		              <li>
		              	<a onClick={e=>{e.preventDefault(); props.signoutUser()}} href="/logout">Logout</a>
		              </li>
		            </ul>
		        </li>}
		      </ul>
		    </nav>
		    <button onClick={ e=>setActiveMenu(!activeMenu)  } type="button" className={`mobile-nav-toggle d-lg-none`}><FontAwesomeIcon icon={['fa', `${activeMenu?'times':'bars'}`]}/> </button>
		    {mobile && activeMenu && <div onClick={ e=>setActiveMenu(!activeMenu) } className="mobile-nav-overly" style={{display:'inherit'}}></div>}
		  </div>
		</div>
    </div>
  );
}

function mapStateToProps( {auth} ){
	return { auth };
}

export default connect( mapStateToProps, { signoutUser } )(withRouter(Header));

