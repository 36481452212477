import React from 'react';
import './song_entry.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Card(props){
  const { recommendation, index, active, onPlayClick, onLikeClick, onDislikeClick } = props;
  const { song, score } = recommendation;
  const artist = song.artists[0]
  
  return  (<div className={`track-entry ${active?'active':''}`}>
      	
      	<span className="pl-5 pl-md-3 playlist-index">{`${index<10?0:''}${index}.`}</span>
  		
  		<div className="pl-3"><FontAwesomeIcon onClick={ e=>{ onPlayClick( song, index) }} className="play-button" icon={['fa', 'play-circle']}/></div>

  		<div className="pl-3 song_info">
          <span className="track"> {song.name} </span>
          {/*<br/>*/}
          <span className="artists"> {artist.name} </span>
        </div>
		
		<div className="ml-auto d-flex pr-3">
			<div className="pr-3"><FontAwesomeIcon onClick={onDislikeClick} className={`${score===-1?'active':''} heart-button`} icon={['fa', 'heart-broken']}/></div>
			<div><FontAwesomeIcon onClick={onLikeClick} className={`${score===1?'active':''} heart-button`} icon={['fa', 'heart']}/></div>
		</div>

    </div>);
}

export default Card;