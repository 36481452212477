import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Home  from './pages/Home';
import NotFound  from './pages/NotFound';
import Login from './pages/Login';
import LoginCallback from './pages/LoginCallback';
import ActivateAccount from './pages/InactiveAccount';

import Auth from './components/HOC/require_auth';

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/" component={ Auth(Home) } />
          <Route exact path="/activate" component={ Auth(ActivateAccount, {active:false}) } />
          <Route exact path="/login" component={ Login } />
          <Route exact path="/login/callback" component={ LoginCallback } />
          <Route path="*" component={ Auth(NotFound) } />
        </Switch>
      </div>
    </Router>
  );
}

export default App;