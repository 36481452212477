import {
	PLAY_SONG, CLEAR_PLAY_SONG, UPDATE_TRACK, //INIT_PLAYER_ERROR
} from '../actions/types';

export default function(state={},action){
	switch(action.type){
		case PLAY_SONG: //Fue exitosa la Auth
			return {...state, play_song: action.payload};
		case CLEAR_PLAY_SONG:
			return {...state, play_song: undefined}
		case UPDATE_TRACK:
			return {...state, ...action.payload}
		// case INIT_PLAYER_ERROR:
		// 	return {...state, initialized:false}
		default:
			return state;
	}
}