import React from 'react';
import { connect } from "react-redux";

import Header from '../components/header/header';
import Player from '../components/player/player';

import Playlist from '../components/playlist/playlist';
import Dropzone from '../components/dropzone/dropzone';
import Uploads from '../components/playlists/playlists';

import Loader from '../components/loader/loader';

function Home(props){
    const { songs, loading, error } = props;

    return  (<div className="with-player">
        <Loader loading={loading} forceQuit={error}/>        
        
        <Header/>
        
        <div className="home-uploads-container">    	
            <div className={`${songs?"col-md-6":""} col-12 h-100 p-0 animated overflow-hidden`}>
                <Dropzone />
            </div>

            <div className={`${songs?"":"hidden"} col-12 col-md-6 h-100 p-0 animated`}>
        		<Playlist/>
        	</div>   
        </div>  

        <div className="home-historic-container">        
            <div className="col-12 pl-0 pr-0">
                <Uploads/>
            </div>   
        </div>  

        <Player/>
    </div>);
}

function mapStateToProps( {playlists, asyncs} ){
    return { songs: playlists.current !== undefined, loading: asyncs.loading, error: asyncs.recommendation_error !== undefined }
}

export default connect( mapStateToProps )(Home);


