// TODO
	//PHONE open spotify with the song 

	// Carrusel
		// mejorar las tarjetas, Title
		// Pagination , Puede esperar por el limite de uploads
		// Bug colores,  The canvas has been tainted by cross-origin data.

	// Calssify View

	// Recomendations
		// Delete
		// Change Name
		// Export to Spotify


var spotify_redirect_uri = 'http://localhost:3000/login/callback';
var api_location = 'http://localhost:8888/api/v1';
var spotify_scope = 'user-read-private user-read-email streaming user-read-birthdate user-top-read user-modify-playback-state user-read-playback-state';
var spotify_cid = '07db8cf9aa154f4ea9680098a1a266fd';
var auth_localstorage_key = "65845arsg46"

if ( process.env.NODE_ENV === 'production' ){
	spotify_redirect_uri = 'https://service.thesoundpic.com/login/callback';
	api_location 		 = 'https://soundpic.cuadra.co/api/v1';
}

export const SPOTIFY_REDIRECT_URI  = spotify_redirect_uri;
export const API_LOCATION          = api_location;
export const SPOTIFY_SCOPE         = spotify_scope;
export const SPOTIFY_CID           = spotify_cid;
export const AUTH_LOCALSTORAGE_KEY = auth_localstorage_key;