import React, {Component} from 'react';
import _ from "lodash";
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";

// TODO remove el extras.active que esta culero
export default function(ComposedComponent, extras = {}){
	
	class Authentication extends Component{
		render(){
			const { auth } = this.props;
			if ( !_.isEmpty(auth) ) {
				if( auth.user.active === true || extras.active === false ){
					return <ComposedComponent {...this.props}  />;
				}
				else{
					return <Redirect to='/activate' />;			
				}
			}
			return <Redirect to='/login' />;	
		}
	}

	function mapStateToProps({auth}){
		return { auth };
	}

	return connect(mapStateToProps)(Authentication);
}