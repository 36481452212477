import React, { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { orientate, compress} from '../utils';
import './dropzone.scss'

import { uploadPicture, clearCurrent, clearTrackRecommendationsError } from '../../actions';
import { connect } from "react-redux";

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCloudUploadAlt, faMagic, faPaperPlane, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
library.add( faCloudUploadAlt, faMagic, faPaperPlane, faArrowUp )


function Dropzone(props) {
  
  const [file, setFile] = useState(undefined);
  const { current } = props; // IF es undefined, subir foto, else usar lo que hay en current
  const displayMode = current !== undefined; //Can't upload during display mode, just set current to undefined

  if (displayMode && file !== undefined){ setFile(undefined) } //Reset File if we are displaying

  useEffect(() => {
    // Update the document title using the browser API
    if ( props.error ){
      alert(props.error.message);
      props.clearTrackRecommendationsError()
    }
  });

  const onDrop = acceptedFiles=>{
    const cfile = acceptedFiles[0];

    if(cfile === undefined) return;

    // Oriantate&Compress picture
    orientate(cfile, orientated_file=>{
        compress(orientated_file, compressed_file=>{         
            compressed_file.url = URL.createObjectURL(compressed_file);
            setFile(compressed_file); //Save for later
        });
    });
  }

  const onClick = e => {
    //REQUEST TO SERVER
    props.uploadPicture(file); 
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple:false, accept: 'image/jpeg, image/png' });

  return (
    <div className="h-100 w-100">
      
      <div className="dropzone-container bxb bxt" {...(!displayMode?getRootProps():{})} style={ displayMode?{backgroundImage:`url("${current.picture.file}")`}:(file?{backgroundImage:`url("${file.url}")`}:{cursor:'pointer'}) }>
          { !displayMode && <input {...getInputProps()} />}
          <div className={`${file!==undefined||displayMode?'invisible':''} w-100 h-100 d-flex flex-column justify-content-center align-items-center`}>
            <FontAwesomeIcon className="upload-icon" icon={['fa', `cloud-upload-alt`]}/>
            <span className="title">Drag & drop</span>
            <span className="subtitle">your pic here, or <u>browse</u></span>
          </div>
      </div>

      <span className={`${file===undefined||displayMode?'hidden':''} fab d-flex justify-content-center align-items-center`}>
        <FontAwesomeIcon onClick={onClick} icon={['fa', `arrow-up`]}/>
      </span>

    </div>
  );
}

function stateToProps( {playlists, asyncs} ){
  return { current:playlists.current, error: asyncs.recommendation_error }
}

export default connect( stateToProps, { uploadPicture, clearCurrent, clearTrackRecommendationsError } )(Dropzone);
