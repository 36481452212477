import React from 'react';
import PopupWindow from '../components/PopupWindow';
import { toQuery, toParams } from '../components/utils';
import { SPOTIFY_CID, SPOTIFY_SCOPE, SPOTIFY_REDIRECT_URI } from '../settings';
import { connect } from "react-redux"
import { Redirect } from 'react-router-dom'
import { signinUser } from "./../actions"
import Crypto from 'crypto'
import _ from "lodash";

function Login(props){
	if ( !_.isEmpty(props.auth) ) return <Redirect to="/"/>

	function spotifyLogin(e){
		
		const auth_query_parameters = {
		    redirect_uri: SPOTIFY_REDIRECT_URI,
		    scope: SPOTIFY_SCOPE,
		    client_id: SPOTIFY_CID,
		    response_type: "code",
		    state: Crypto.randomBytes(20).toString('hex'),
		    // show_dialog: true,
		}
		
		const search = toQuery(auth_query_parameters);

		// Checar en Mobile si no simplemente usar una etiqueta a y cachar los params de esta url
	    const popup = PopupWindow.open(
	      'spotify-authorization',
	      `https://accounts.spotify.com/authorize?${search}`,
	      { height: 1000, width: 600 }
	    );

	    // this.onRequest();
	    popup.then(
	      ({location})  => {
			var query = location.replace(`${auth_query_parameters.redirect_uri}?`, "");
			const { state,code } = toParams(query);
			if (state === auth_query_parameters.state){
				props.signinUser('spotify',{code});
			}
	      },
	      error => { console.error(error) }
	    );

	}

	return <div className="login-container">
		<img src="assets/images/shape/home_6/feature-1.png" className="bg-img" alt="bg"/>
	 	
	 	<div className="container h-100 d-flex flex-column justify-content-center align-items-center">
	 		<div className="central-wrapper">
	 			<img src="assets/images/logo-black2.png" className="logo" alt="soundpic"/>
	 			<p>
	 				An AI service to discover music, just upload a picture and start discovering.
	 			</p>
		 		
				<div onClick={spotifyLogin} className="spotify-button m-auto"/>
			</div>
		</div>

	</div>
}

function mapStateToProps(state){
	return state;
}

export default connect( mapStateToProps, { signinUser } )(Login);
