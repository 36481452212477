import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.css';
import './styles.scss'

// REDUX
import { Provider } from "react-redux"
import { createStore, applyMiddleware } from "redux"
import reducers from "./reducers"
import thunk from "redux-thunk"
import { AUTH_USER } from "./actions/types"
import { signoutUser, updateUser } from "./actions"
import { AUTH_LOCALSTORAGE_KEY } from './settings'
import axios from 'axios';

const middleware = [thunk]
const createStoreWithMiddleware = applyMiddleware(...middleware)(createStore)
export const store = createStoreWithMiddleware(reducers)

//Auth on refresh
const auth = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE_KEY))

if (auth) {
   const elapsed = Date.now() / 1000 - auth.timestamp
   
   if ( elapsed < auth.expires_in ) { //Still valid
      axios.defaults.headers.common["Authorization"] = `Token ${auth.token}`
      store.dispatch({ type: AUTH_USER, payload: auth })
   } else { //Token Expired, refresh
      // OBTENER 'spotify' del auth
      axios.post(`/signin/spotify/refresh`, auth, {headers:{'Authorization':`Token ${auth.token}`}}).then( ({data}) => {        
          store.dispatch( updateUser(auth, data) );
        }).catch( e => { 
          store.dispatch( signoutUser() )
      });
   }
}

ReactDOM.render(
   <Provider store={store}>
     <App />    
   </Provider>,
   document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();