import { combineReducers } from 'redux';

import authReducer    from "./auth_reducer";
import playerReducer from './player_reducer';
import playlistsReducer from './playlists_reducer';
import asyncsReducer from './asyncs_reducer';

const rootReducer = combineReducers({
	auth     : authReducer, 		//Tokens/user info
	player   : playerReducer,		//Current Playing
	playlists: playlistsReducer, //Current upload and all the others results
	asyncs   : asyncsReducer
});

export default rootReducer;