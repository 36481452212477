import {
	TRACK_RECOMMENDATIONS,
	CLEAR_CURRENT_RECOMMENDATIONS,
	CURRENT_RECOMMENDATIONS,
	RECOMMENDATION_FEEDBACK,
	FETCH_RECOMMENDATIONS

} from '../actions/types';

import _ from 'lodash';

export default function(state={},action){
	switch(action.type){
		case TRACK_RECOMMENDATIONS: //1 Picture was uploaded and we got songs
			return { 
				...state, 
				[action.payload.picture.id]:{ ...action.payload } 
			};
		case CURRENT_RECOMMENDATIONS: 
			return { 
				...state, 
				current: { ...action.payload } 
			};
		case CLEAR_CURRENT_RECOMMENDATIONS:
			return {
				...state,
				current: undefined
			}
		case FETCH_RECOMMENDATIONS: 
			return { 
				...state, 
				..._.keyBy( action.payload.data , 'id')
			};
		case RECOMMENDATION_FEEDBACK: //Modificar Current
			var { recommendations } = state.current;
			var index = _.findIndex(recommendations, {song:{spotify_id:action.payload.song.spotify_id}});
			recommendations[index] = action.payload
			return {...state, current: {...state.current, recommendations:[...recommendations] } };
		default:
			return state;
	}
}