import React from "react";
import { connect } from "react-redux";
import { clearPlaySong, updateCurrentTrack } from '../../actions';
import SeekBar from './controls/seekbar';
import SpotifyWebApi from 'spotify-web-api-js';
import "./player.scss";

import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlayCircle, faPauseCircle, faStepForward, faStepBackward, faHeart, faHeartBroken } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
library.add( faPlayCircle, faPauseCircle, faStepForward, faStepBackward, faHeart, faHeartBroken )

function millisecondsToTime( time_in_mili )
{
      // var mil = time_in_mili % 1000;
      var sec = Math.floor((time_in_mili / 1000) % 60);
      var min = Math.floor((time_in_mili / (60 * 1000)) % 60);
      return `${min<10?0:''}${min}:${sec<10?0:''}${sec}`;
}

class Player extends React.Component {
   constructor(props) {
      super(props)
      this.spotifyApi = new SpotifyWebApi();
      this.spotifyApi.setAccessToken(props.token);
      this.state = { 
         token:props.token,
         position  :undefined,
         duration  :undefined,
         trackName :undefined,
         artistName:undefined,
         albumName :undefined,
         playing   :undefined,
         image     :undefined,
         deviceId  :undefined,        //This player ID
         currentPlayer : undefined,   //Ver si está reproduciendo en otro lado
         currentTrackId: undefined,   //Current Playing
         canPlayOnDevice : undefined, //If Browser is allowed to play music
      }
      
      this.setMyCurrentPlayback = this.setMyCurrentPlayback.bind(this);
      this.onSeek               = this.onSeek.bind(this);
      this.onDislike = this.onDislike.bind(this)
      this.onPrevClick = this.onPrevClick.bind(this)
      this.onToggleClick = this.onToggleClick.bind(this)
      this.onNextClick = this.onNextClick.bind(this)
      this.onLike = this.onLike.bind(this)

      this.playerCheckInterval  = null; //Checar que SDK de Spotify esta listo, componentDidMount
      this.positionUpdater      = null;
   }

   playSong(song){
      const { deviceId, currentPlayer, canPlayOnDevice } = this.state;
      
      if( currentPlayer === undefined ){
         if( canPlayOnDevice === true ){ // NO tiene ningun reproductor y podemos jalar sonido al browser 
           this.spotifyApi.transferMyPlayback([deviceId]).then(ans0=>{
              // Reproducir Cancion desp de 1s
              setTimeout(e=>{ this.spotifyApi.play({"uris": song}); },1000); //Debemos esperar por la API
              //No se necesita actualizar estado aqui
           }).catch(e=>{
              console.error(e)
           })
           this.setState( { currentPlayer:{id:deviceId} } )
         }else{
          // NO tiene reproductor y el browser no acepta reproducciones, redirigir a la app de spotify
          window.open(`https://open.spotify.com/track/${song[0].replace('spotify:track:','')}`,'_blank');
         }
      }
      else{
         // Reproducir cancion
         this.spotifyApi.play({"uris": song});
         // Actualizar el estado
         setTimeout(e=>{ this.setMyCurrentPlayback(); },1000); //Debemos esperar por la API

      }
   }

   componentDidUpdate(prevProps, prevState) {
    const next_song = this.props.redux_player.play_song;

    // We have to play a new song
    if( prevProps.redux_player.play_song === undefined && next_song!==undefined ){
      this.playSong(next_song);
      this.props.clearPlaySong();
    }
  }

   checkForPlayer() {
     const { token } = this.state;

     if (window.Spotify !== null && window.Spotify !== undefined  ) {    // Spotify debe ser accesible en window, agregar script a main index
       clearInterval(this.playerCheckInterval);
       this.player = new window.Spotify.Player({
         name: "Soundpic",
         getOAuthToken: cb => { cb(token); },
       });
       this.createEventHandlers();

       // finally, connect!
       this.player.connect();
     }
   }

   createEventHandlers() { //Una vez que tenemos player
     this.player.on('initialization_error', e => { 
        // this.props.initPlayerError()
        this.setState({ canPlayOnDevice: false });
      });
     this.player.on('authentication_error', e => { 
        window.location.reload();
      });
     this.player.on('account_error', e => { console.error('account e',e); });
     this.player.on('playback_error', e => { console.error('Playback e',e); });

     // Playback status updates
     this.player.on('player_state_changed', state => { this.onPlayerStateChanged(state); });

     // Ready
     this.player.on('ready', data => {
       let { device_id } = data;
       this.setState({ deviceId: device_id, canPlayOnDevice: true });
     });
   }

   // EDITAR junto con setMyCurrentPlayback
   onPlayerStateChanged(state) {
     if (state !== null) { // if we're no longer listening to music, we'll get a null state.
       const { current_track: currentTrack } = state.track_window;
       const trackName  = currentTrack.name;
       const albumName  = currentTrack.album.name;
       const artistName = currentTrack.artists.map(artist => artist.name).join(", ");
       const playing    = !state.paused;
       const image      = currentTrack.album.images[0];

       this.setState({
         position:state.position,
         duration:state.duration,
         trackName,
         artistName,
         albumName,
         image,
         playing,
         currentTrackId: currentTrack.id
       });

       this.props.updateCurrentTrack( {id:currentTrack.id} );
     }else{
       //TODO Resetear estado??
       this.props.updateCurrentTrack( {id:undefined} );
     }
   }

   // Ejecutar cada X tiempo mientras este playing??
   // EDITAR junto con onPlayerStateChanged
   setMyCurrentPlayback(){
      this.spotifyApi.getMyCurrentPlaybackState().then(ans=>{
         if (ans){
            const {item, device} = ans;
            const {album}= item;

            const trackName  = item.name;
            const artistName = item.artists.map(artist => artist.name).join(", ");
            const albumName  = album.name;
            const image      = album.images[0];
            const trackId    = item.id

            this.setState({
               position: ans.progress_ms,
               duration: item.duration_ms,
               trackName,
               artistName,
               albumName,
               image,
               playing : ans.is_playing,
               currentPlayer: device,
               currentTrackId: trackId
            });

            this.props.updateCurrentTrack( {id:trackId} );
         }
         else{
            this.props.updateCurrentTrack( {id:undefined} );
         }
      }).catch(e=>{
         console.error(e);
      });
   }

   updatePosition(){
    const { playing, position, duration } = this.state;

    if( playing === true){
      const new_pos = position + 1000;

      if(new_pos > duration){
        this.setState({playing:false}); //Pausar temporalmente
        this.setMyCurrentPlayback();    //Actualizar
      }else{
        this.setState({position:position+1000});      
      }
    }

   }

   componentDidMount() {
      this.playerCheckInterval = setInterval(() => this.checkForPlayer(), 1000);
      this.positionUpdater     = setInterval(() => this.updatePosition(), 1000);
      this.setMyCurrentPlayback();
   }

   componentWillUnmount(){
      clearInterval( this.positionUpdater );
   }

   onPrevClick(e) {
     this.spotifyApi.skipToPrevious().then(e=>{ 
         setTimeout(this.setMyCurrentPlayback,500);
      }).catch(e=>{
         console.error(e)
      })
   }

   onToggleClick(e) {
      const {playing} = this.state;
      if(playing){
         this.spotifyApi.pause().then(e=>{
            this.setState({playing:false});
         }).catch(e=>{
            this.setState({playing:true});
         })
      }else{
         this.spotifyApi.play().then(e=>{
            this.setState({playing:true});
         }).catch(e=>{
            this.setState({playing:false});
         })
      }  
   }

   onNextClick() {
      this.spotifyApi.skipToNext().then(e=>{ 
         setTimeout(this.setMyCurrentPlayback,500);
      }).catch(e=>{
         console.error(e)
      })
   }

   onLike(e){
    console.log(`LIKE ${this.state.currentTrackId}`);
   }

   onDislike(e){
    console.log(`DISLIKE ${this.state.currentTrackId}`);
   }

   onSeek(e){
    const {value} = e.target;
    
    this.spotifyApi.seek( Math.floor(value) ).then(ans=>{
      setTimeout(this.setMyCurrentPlayback,500);
    }).catch(e=>{
      console.error(e);
    });

   }

   render() {
      const { artistName, trackName, albumName, playing, image, position, duration } = this.state; //currentPlayer -> pick device
      const img_src = image?image.url:'';

      if (playing===undefined) return <div/>

      return (<div className="player-container">
         <SeekBar position={position} duration={duration} onChange={ this.onSeek }/>
         <div className="song-status">
          <span></span>
          <span>{millisecondsToTime(position)} - {millisecondsToTime(duration)}</span>
         </div>
         
          <div className="info">
            
            <img className="d-none d-md-inline" src={img_src} alt={albumName}/>
            
            <div className='pl-2 pl-md-0 song_info'>
              <span className="track">   {trackName}  </span>
              <span className="artists"> {artistName} </span>
            </div>
          </div>

         <div className="controls">
            {/*<div onClick={this.onDislike}> <FontAwesomeIcon className="" icon={['fa', `heart-broken`]}/></div>*/}
            
            <div onClick={this.onPrevClick}>  <FontAwesomeIcon className="backward" icon={['fa', `step-backward`]}/></div>
            <div onClick={this.onToggleClick}><FontAwesomeIcon className="toggle-song" icon={['fa', `${playing?'pause-circle':'play-circle'}`]}/></div>
            <div onClick={this.onNextClick}>  <FontAwesomeIcon className="forward" icon={['fa', `step-forward`]}/></div>
            
            {/*<div onClick={this.onLike}> <FontAwesomeIcon className="active" icon={['fa', `heart`]}/></div>*/}
         </div>
      </div>)
   }
}

function mapStateToProps( {player, auth} ) {
   return { token:auth.access_token, redux_player:player }
}

export default connect( mapStateToProps, {clearPlaySong, updateCurrentTrack } )( Player )
