import React from 'react';
import { connect } from "react-redux";
import { playSong, sendFeedback } from '../../actions';
import Entry from '../song_entry/song_entry'
import './playlist.scss'

function PlaylistView(props){
	const { current, currentPlaying } = props;

	if( current === undefined ) return <div></div>

	const { recommendations } = current;

	function onPlayClick( {spotify_id}, ix ){ // Play whole playlist
		const toPlay = recommendations.slice( ix-1 ).map( r => `spotify:track:${r.song.spotify_id}` )
		props.playSong( toPlay );
	}

	function feedback(recommendation, score){ //1,-1
		//toggle to 0 if rec.score === score
		props.sendFeedback( current.picture.id, recommendation.song.spotify_id, recommendation.score === score?0:score);
	}

	return <div className="bxl bxt w-100 h-100">
		<div className="bxb playlist-tracks h-100">
			<div className="bxb p-3 pl-1 playlist-header">
				<span>Recommendations</span>
			</div>
			{ recommendations && recommendations.map( (r,i) => {
				return <Entry onLikeClick={ (e) => feedback(r,1)} onDislikeClick={ (e) => feedback(r,-1)} onPlayClick={onPlayClick} key={i} recommendation={r} active={ currentPlaying === r.song.spotify_id } index={i+1}/>
			} ) }
		</div>
	</div>
}

function stateToProps( {playlists, player} ){
	return { current: playlists.current?playlists.current:undefined, currentPlaying: player.id };
}

export default connect( stateToProps , { playSong, sendFeedback } )(PlaylistView);