import axios from 'axios';
import {
	AUTH_USER,
	UNAUTH_USER,
	TRACK_RECOMMENDATIONS,
	TRACK_RECOMMENDATIONS_ERROR,
	CURRENT_RECOMMENDATIONS,
	CLEAR_CURRENT_RECOMMENDATIONS,
	FETCH_RECOMMENDATIONS,
	RECOMMENDATION_FEEDBACK,
	PLAY_SONG,
	CLEAR_PLAY_SONG,
	UPDATE_TRACK,
	LOADING_UPDATE,
	// INIT_PLAYER_ERROR
} from './types';
import { API_LOCATION, AUTH_LOCALSTORAGE_KEY } from '../settings';

// axios.defaults.withCredentials = true;
axios.defaults.baseURL = API_LOCATION;

export function signinUser(provider, args){
	return dispatch =>  {
		axios.post(`/signin/${provider}`,args).then( ({data}) => {
			const tos = data.data;
			localStorage.setItem(AUTH_LOCALSTORAGE_KEY, JSON.stringify(tos));
			axios.defaults.headers.common['Authorization'] = `Token ${tos.token}`;
			dispatch({type: AUTH_USER, payload: tos });
		}).catch( ({response}) => { 
			console.error(response);
			// dispatch( authError(response.data.detail) )
		});
	};
}

export function signoutUser(){
	// axios.post() //Pegarle al endpoint logout?
	localStorage.removeItem(AUTH_LOCALSTORAGE_KEY); 
	axios.defaults.headers.common['Authorization'] = "";
	
	return {
		type: UNAUTH_USER,
		payload: {}
	}
}

export function updateUser( prevUser, newData ){
	// UPDATEAR prev user
	const newUser = {...prevUser, ...newData };
	localStorage.removeItem(AUTH_LOCALSTORAGE_KEY); 
	localStorage.setItem(AUTH_LOCALSTORAGE_KEY, JSON.stringify(newUser));
	axios.defaults.headers.common['Authorization'] = `Token ${newUser.token}`;
	return { type: AUTH_USER, payload: newUser }
}

export function uploadPicture( file ){
	return dispatch =>{
		var fd = new FormData();
	    fd.append('image',file);
	    
	    dispatch( updateLoader(true) );
	    axios.post('get_song', fd).then(({data})=>{
		  dispatch( setCurrent(data.data) );	      
	      dispatch({type: TRACK_RECOMMENDATIONS,   payload:data.data });
	      dispatch( updateLoader(false) );
	    }).catch( ({response}) => {  
	    	
	    	if( response.status === 406 ){
	      		dispatch({type: TRACK_RECOMMENDATIONS_ERROR ,payload:{message:"Max Uploads reached for BETA version"}})
	    	}
	      	else{
	      		dispatch({type: TRACK_RECOMMENDATIONS_ERROR ,payload:{message:"Error, try again later"}})
	      	}

	      	dispatch( clearCurrent() );
	      	dispatch( updateLoader(false) );
	    })  
	}
}

export function clearTrackRecommendationsError(){
	return {type: TRACK_RECOMMENDATIONS_ERROR ,payload: undefined}
}

export function updateLoader(bool_flag){
	return { type: LOADING_UPDATE, payload: bool_flag }
}

export function setCurrent( playlist ){
	return {
		type: CURRENT_RECOMMENDATIONS, 
		payload: playlist
	}
}

export function clearCurrent(){
	return {
		type: CLEAR_CURRENT_RECOMMENDATIONS, 
		payload: undefined
	}
}

export function fetchRecommendations( page ){
	return dispatch=>{
		axios.get('me/recommendations',{page}).then(({data})=>{
			dispatch({type:FETCH_RECOMMENDATIONS, payload:data});
		}).catch(e=>{
			console.error(e);
		})
	}
}

// pictureId, songId, score (-1,1)
export function sendFeedback(picture, song, score){
	return dispatch=>{
		axios.post('feedback/picture',{picture,song,score}).then(({data})=>{
			dispatch({type:RECOMMENDATION_FEEDBACK, payload:data.data});
		}).catch(e=>{
			console.error(e);
		});
	}
}

// Returns array
export function playSong( song_object ){	
	return {
		type: PLAY_SONG,
		payload: Array.isArray(song_object)?song_object:[song_object]
	}
}

export function updateCurrentTrack( track_object ){
	return {
		type: UPDATE_TRACK,
		payload: track_object
	}
}

export function clearPlaySong(){
	return {
		type: CLEAR_PLAY_SONG,
		payload: {} 
	}
}

// export function initPlayerError(){
// 	return {
// 		type: INIT_PLAYER_ERROR,
// 		payload: false
// 	}
// }
