import React from 'react';
import { connect } from "react-redux";

import Header from '../components/header/header.jsx';
import Player from '../components/player/player.jsx';

function Home(props){
  return  (<div>
  		<Header/>
      	<div> Under construction </div>
      	<Player/>
    </div>);
}

export default connect( state=>state.auth, null )(Home);
