import React from "react";
import "./loader.scss";

import Spritesheet from 'react-responsive-spritesheet';

export default class Loader extends React.Component {
   constructor(props) {
      super(props)
      this.state = { 
      	spritesheet : null, 
      	loop        : false,
      	loading     : props.loading, 
      	running     : props.loading,
      	forcedStop  : false,
      }
	}

	static getDerivedStateFromProps(props, state) {
		
		if( props.forceQuit === true && state.running ){
			return {
		        forcedStop: true, 
		    }; //Running but we must stop it
		}

	    // We must start the loading animation
	    if (state.loading === false && props.loading === true) {
		    return {
		        running: true, //Start animation
		        loading: true,
		      };
		}

		// We are showing animation but loading has finished
		if ( state.loading === true && props.loading === false ){
			return {
				loading: false
			} //Just change loading, the component modifies running by itself
		}
		
    	return null;
  	}

  	// original 869x1050
  	// min      300x362

	render(){
		const { spritesheet, loop, running, forcedStop } = this.state;

		if( !running )  return <div/> 

		return <div className={`loader-container ${forcedStop?'invisible':''}`}>
			
			<div className="loader">
				<Spritesheet
	        		image="assets/images/loader/spritesheet_min.png"
	        		widthFrame={300}
	        		heightFrame={362}
	        		steps={84} 
	        		fps={20}
	                autoplay={true}
	                loop={loop}
			        getInstance={ ss => { this.setState({spritesheet:ss}) } }
			        onEnterFrame={[
			          {
			            frame: 38,
			            callback: (() => {
			              if( this.state.loading === true && spritesheet !== undefined){
			              	spritesheet.setStartAt(38)
			              	spritesheet.setEndAt(59)
			              	this.setState({loop:true});
			              }
			            })
			          },
			          {
			            frame: 58,
			            callback: (() => {
			              if( this.state.loading === false && spritesheet !== undefined ){
			              	spritesheet.setStartAt(59);
			              	spritesheet.setEndAt(84);
			              	this.setState({loop:false});
			              }
			            })
			          },
			          {
			            frame: 83,
			            callback: (() => {
			            	this.setState({
			            		running    :false,
			            		forcedStop :false,
			            	});
			            })
			          }
			        ]}/>
			    <span className="blinking">ANALYZING</span>
			</div>
		</div>
	}
}