export const AUTH_USER = 'AUTH_USER';
export const UNAUTH_USER = 'UNAUTH_USER';

export const TRACK_RECOMMENDATIONS 		 = 'TRACK_RECOMMENDATIONS';
export const TRACK_RECOMMENDATIONS_ERROR = 'TRACK_RECOMMENDATIONS_ERROR';
export const CURRENT_RECOMMENDATIONS 	 = 'CURRENT_RECOMMENDATIONS';
export const CLEAR_CURRENT_RECOMMENDATIONS = "CLEAR_CURRENT_RECOMMENDATIONS";
export const RECOMMENDATION_FEEDBACK 	 = 'RECOMMENDATION_FEEDBACK';

export const FETCH_RECOMMENDATIONS = "FETCH_RECOMMENDATIONS";

export const PLAY_SONG = 'PLAY_SONG';
export const CLEAR_PLAY_SONG = 'CLEAR_PLAY_SONG';
export const UPDATE_TRACK='UPDATE_TRACK';

export const LOADING_UPDATE = 'LOADING_UPDATE';
// export const INIT_PLAYER_ERROR = "INIT_PLAYER_ERROR";