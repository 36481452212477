import React, { useState } from 'react';
import { connect } from "react-redux";
import { signoutUser, updateUser } from "./../actions"
import axios from 'axios';

import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import './InactiveAccount.scss'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
library.add( faLongArrowAltRight )

function InAc(props){
	const [ inviteCode, setInviteCode] = useState( '' );
	console.log( props );

	const onSubmit = e => {
		e.preventDefault();

		if ( inviteCode.length < 20 ) {
			axios.post('invite_code', { code: inviteCode }).then( ({data}) =>{
				
				props.updateUser( props.auth, { user : data.data } );
				setInviteCode("");

			}).catch(e=>{
				
				NotificationManager.error('Wrong/Expired Code', "Error");

			})
		}
		else{
			NotificationManager.error('Wrong Code', "Error");
		}
	}

	// IF we are active there is no need to be here
	if (props.auth.user.active) { props.history.push('/') }

	// LABEL border-color es la que se debe cambiar cuando hay error o nel
    return  (<div className="inactive_account h-100 w-100 d-flex justify-content-center align-items-center">
		
    	<div className="position-absolute">
    		<div className="beta_info">
	    		<h1>
	    			Welcome to the public <span>BETA</span>
	    		</h1>
	    		<p>
	    			Please fill in with your invitation code
	    		</p>
	    	</div>
			<form onSubmit={onSubmit}>
			  <input value={inviteCode} onChange={ e=>setInviteCode(e.target.value) } type="text" name="name" className="question invalid" id="nme" required autoComplete="off" />
			  <label htmlFor="nme"><span>Invite code</span></label>

			  <div onClick={ onSubmit  } className={`code-submit ${inviteCode.length>0?'enabled':''}`}><FontAwesomeIcon icon={['fa', 'long-arrow-alt-right']}/> </div>
			 
			</form>

			<div className="beta_contact">
	    		<p>
	    			If you don't have one you can always <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/TheSoundpic">send us a message</a> to receive one
	    		</p>
	    	</div>
		</div>

		<div onClick={ e=>props.signoutUser()  } className='logout-bttn'><FontAwesomeIcon icon={['fa', 'times']}/> </div>
    	<NotificationContainer/>
    </div>);
}

function mapStateToProps( {auth} ){
    console.log(auth);
    return { auth }
}

export default connect( mapStateToProps, { signoutUser, updateUser } )(InAc);
