import React from 'react';
import './seekbar.scss'

export default function SeekBar(props){
	const { duration, position, onChange } = props;
	
	return <div className="controls-seek-bar">
		<input
		readOnly
        type="range"
        step="any"
        onChange={onChange?onChange:e=>{}}
        max={duration.toFixed(4)}
        value={position}/>
    </div>
}